import React from 'react'
import {Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.css'

const Home = React.lazy(() => import('./pages/Home'));

function App() {
    return (
        <React.Fragment>
                <Routes>
                <Route path="/" element={<Home />} />
                </Routes>
        </React.Fragment>
    );
}

export default App;
